export const navLinks = [
  { path: "/", text: "Home" },
  { path: "/projects", text: "Projects" },
  { path: "/about", text: "About" },
  { path: "/contact", text: "Contact" },
  //   { path: "/art", text: "Art" },
  //   { path: "/blog", text: "Blog" },
];

// export const socialLinks = [
//   { path: "/", text: "Home" },
// ]
